const RestaurantList = [
  {
    name: "Mercato Kitchen and Cocktails",
    address: {
      street: "4958 Merrick Road",
      city: "Massapequa Park",
      zipcode: "11762",
      state: "NY",
    },
    latLng: {
      lat: 40.665628,
      lng: -73.45195,
    },
    site: "https://www.mercatokitchen.com",
  },
  {
    name: "Bacaro Italian Tavern",
    address: {
      street: "1020 Park Blvd",
      city: "Massapequa Park",
      zipcode: "11762",
      state: "NY",
    },
    latLng: {
      lat: 40.6789351,
      lng: -73.4560477,
    },
    site: "https://www.bacarony.com/",
  },
  // {
  //   name: "Brick & Cork",
  //   address: {
  //     street: "4314 Merrick Rd",
  //     city: "Massapequa",
  //     zipcode: "11758",
  //     state: "NY",
  //   },
  //   phone: "(516) 654-9517",
  //   latLng: {
  //     lat: 40.6664129,
  //     lng: -73.4745582,
  //   },
  //   site: "https://www.brickandcork.com/?utm_source=google-gmb-maps&utm_medium=gmb-website-button&utm_campaign=locl.io&utm_content=a5df86c1-5a13-4ea1-9150-006787087d81",
  // },
  // {
  //   name: "Frankie’s Pizzeria & Restaurant",
  //   address: {
  //     street: "1696 Merrick Rd",
  //     city: "Merrick",
  //     zipcode: "11566",
  //     state: "NY",
  //   },
  //   tel: "516-377-9500",
  //   latLng: {
  //     lat: 40.6537072,
  //     lng: -73.5599419,
  //   },
  //   site: "http://foodemporium.keyfood.com/",
  // },
  // {
  //   name: "Wagyu Steak House",
  //   address: {
  //     street: "1035 W Montauk Hwy",
  //     city: "Babylon",
  //     zipcode: "11704",
  //     state: "NY",
  //   },
  //   tel: "631-482-1606",
  //   latLng: {
  //     lat: 40.6847149,
  //     lng: -73.3511605,
  //   },
  //   site: "http://wagyusteakhouseli.com/",
  // },
  // {
  //   name: "Seaside Grill",
  //   subText: "Closed For the season",
  //   address: {
  //     street: "451 E Main St",
  //     city: "Riverhead",
  //     state: "NY",
  //     zipcode: "11901",
  //   },
  //   tel: "631-574-8103",
  //   latLng: {
  //     lat: 40.9183472,
  //     lng: -72.6559399,
  //   },
  //   site: "https://www.seaside-grill.com/",
  // },
  // {
  //   name: "Minnow At The Galley Ho",
  //   address: {
  //     street: "650 1st St",
  //     city: "New Suffolk",
  //     state: "NY",
  //     zipcode: "11956",
  //   },
  //   tel: "631-734-8474",
  //   latLng: {
  //     lat: 40.9920482,
  //     lng: -72.4712089,
  //   },
  //   site: "https://www.minnowrestaurant.com/",
  // },
  // {
  //   name: "Esca's Pizza",
  //   address: {
  //     street: "90-17 Rockaway Blvd",
  //     city: "Ozone Park",
  //     state: "NY",
  //     zipcode: "11416",
  //   },
  //   tel: "929-556-3033",
  //   latLng: {
  //     lat: 40.681923,
  //     lng: -73.849194,
  //   },
  //   site: "https://escaspizza.com/",
  // },
  // {
  //   name: "Dark Horse Tavern",
  //   address: {
  //     street: "1029 Park Blvd",
  //     city: "Massapequa Park",
  //     state: "NY",
  //     zipcode: "11762",
  //   },
  //   tel: "516-799-4067",
  //   latLng: {
  //     lat: 40.6792284,
  //     lng: -73.4580388,
  //   },
  //   site: "https://www.darkhorsetavern.net/massapequa",
  // },

  // {
  //   name: "Sweet Jane",
  //   address: {
  //     street: "64 E Main St",
  //     city: " Bay Shore",
  //     state: "NY",
  //     zipcode: "11706",
  //   },
  //   tel: "",
  //   latLng: {
  //     lat: 40.72247280390312,
  //     lng: -73.24633843469216,
  //   },
  //   site: "https://sweetjanebayshore.com",
  // },
  // {
  //   name: "American Standard Whiskey Bar & Grill",
  //   address: {
  //     street: " 53 W Main St",
  //     city: " Bay Shore",
  //     state: "NY",
  //     zipcode: "11706",
  //   },
  //   tel: "",
  //   latLng: {
  //     lat: 40.72093101738179,
  //     lng: -73.24839681083986,
  //   },
  //   site: "https://americanstandardbar.com",
  // },
  // {
  //   name: "Athenian Greek Taverna",
  //   address: {
  //     street: "2188 Jericho Tpke",
  //     city: "Commack",
  //     state: "NY",
  //     zipcode: "11725",
  //   },
  //   tel: "(631) 499-7660",
  //   latLng: {
  //     lat: 40.842475197454185,
  //     lng: -73.2871988219225,
  //   },
  //   site: "https://www.atheniangreektaverna.com/",
  // },

  // {
  //   name: "Anthony's Kitchen & Cocktails",
  //   address: {
  //     street: "2601 Merrick Rd",
  //     city: "Bellmore",
  //     state: "NY",
  //     zipcode: "11710",
  //   },
  //   tel: "(516) 408-5311",
  //   latLng: {
  //     lat: 40.66302472724316,
  //     lng: -73.53004382445852,
  //   },
  //   site: "https://anthonysofbellmore.com/",
  // },
  // {
  //   name: "South Shore Craft Brewery",
  //   address: {
  //     street: "3505 Hampton Rd",
  //     city: "Oceanside",
  //     state: "NY",
  //     zipcode: "11572",
  //   },
  //   tel: "(516) 388-6685",
  //   latLng: {
  //     lat: 40.62521053016395,
  //     lng: -73.65417803455223,
  //   },
  //   site: "https://www.southshorecraftbrewery.com/",
  // },
  {
    name: "La Casa Cafe + Bar",
    address: {
      street: "157 Mamaroneck Ave",
      city: "Mamaroneck",
      state: "NY",
      zipcode: "10543",
    },
    tel: "(914) 300-1135",
    latLng: {
      lat: 40.949969180505825,
      lng: -73.73295781119174,
    },
    site: "https://www.lacasamamaroneck.com/",
  },
  {
    name: "Tap & Tapas",
    address: {
      street: "487 Main Street",
      city: "Northport",
      state: "NY",
      zipcode: "11768",
    },
    tel: "(917) 226-4664",
    latLng: {
      lat: 40.90147294111067,
      lng: -73.33645419998992,
    },
    site: "https://tapandtapas.net/",
  },
  {
    name: "Beginnings",
    address: {
      street: "1986 Park Street",
      city: "Atlantic Beach",
      state: "NY",
      zipcode: "11509",
    },
    tel: "(516) 239-7483",
    latLng: {
      lat: 40.589583771846534,
      lng: -73.73471673139154,
    },
    site: "http://www.beginningsrestaurant.com/",
  },
  // {
  //   name: "JTI's American Kitchen & Bar",
  //   address: {
  //     street: "91 Howells Road",
  //     city: "Brightwaters",
  //     state: "NY",
  //     zipcode: "11718",
  //   },
  //   tel: "(613) 647-5900",
  //   latLng: {
  //     lat: 40.73081606689016,
  //     lng: -73.26875442352603,
  //   },
  //   site: "https://jtisli.com/",
  // },
  // {
  //   name: "The Milleridge Inn & Carriage House",
  //   address: {
  //     street: "585 North Broadway",
  //     city: "Jericho",
  //     state: "NY",
  //     zipcode: "11753",
  //   },
  //   tel: "(516) 931-2201",
  //   latLng: {
  //     lat: 40.79175610312451,
  //     lng: -73.53774295696428,
  //   },
  //   site: "https://milleridgeinn.com",
  // },
  // {
  //   name: "Coastal Kitchen & Daquiri Bar",
  //   address: {
  //     street: "12 E Main Street",
  //     city: "Bay Shore",
  //     state: "NY",
  //     zipcode: "11706",
  //   },
  //   tel: "(631) 665-3030",
  //   latLng: {
  //     lat: 40.72168983658076,
  //     lng: -73.24725793684307,
  //   },
  //   site: "https://coastalliny.com/",
  // // },
  // {
  //   name: "Norcina",
  //   address: {
  //     street: "186 N Main St",
  //     city: "New City",
  //     state: "NY",
  //     zipcode: "10956",
  //   },
  //   tel: "(845) 638-8030",
  //   latLng: {
  //     lat: 41.15587015875435,
  //     lng: -73.98559020234187,
  //   },
  //   site: "https://www.norcinanewcity.com/",
  // },
  // {
  //   name: "The Tasty Table",
  //   address: {
  //     street: "21 Campwoods Rd",
  //     city: "Ossining",
  //     state: "NY",
  //     zipcode: "10562",
  //   },
  //   tel: "(914)) 762-4000",
  //   latLng: {
  //     lat: 41.169027171520725,
  //     lng: -73.84291697093434,
  //   },
  //   site: "thetastytableny.com",
  // },
  // {
  //   name: "Restoration Kitchen & Cocktails",
  //   subText: "",
  //   address: {
  //     street: "49 E Hoffman Ave",
  //     city: "Lindenhurst",
  //     state: "NY",
  //     zipcode: "10157",
  //   },
  //   tel: "631-592-1905",
  //   latLng: {
  //     lat: 40.687319472158414,
  //     lng: -73.37154090897289,
  //   },
  //   site: "https://www.restorationli.com/",
  // },
  {
    name: "Dirty Taco + Tequila Smithtown",
    subText: "",
    address: {
      street: "49 E Hoffman Ave",
      city: "Smithtown Bypass ",
      state: "NY",
      zipcode: "11787",
    },
    seasonal: true,
    latLng: {
      lat: 40.84929000835683,
      lng: -73.16823895771459,
    },
    site: "https://www.dirtytacoandtequila.com/",
  },
  {
    name: "Dirty Taco & Tequila Port Washington",
    seasonal: true,
    subText: "",
    address: {
      street: "10 Matinicock Ave",
      city: "Port Washington",
      state: "NY",
      zipcode: "11050",
    },
    tel: "516-439-4733",
    latLng: {
      lat: 40.83633160360826,
      lng: -73.70512862886017,
    },
    site: "https://www.dirtytacoandtequila.com/",
  },
  {
    name: "Dirty Taco & Tequila Port Patchogue",
    seasonal: true,
    subText: "",
    address: {
      street: "32 West Main St",
      city: "Patchogue",
      state: "NY",
      zipcode: "11772",
    },
    tel: "631-977-8226",
    latLng: {
      lat: 40.76567183265841,
      lng: -73.0159278979432,
    },
    site: "https://www.dirtytacoandtequila.com/",
  },
  {
    name: "Dirty Taco & Tequila Port RVC",
    seasonal: true,
    subText: "",
    address: {
      street: "201 Sunrise Hwy",
      city: "Rockville Centre",
      state: "NY",
      zipcode: "11570",
    },
    tel: "516-600-9874",
    latLng: {
      lat: 40.7656921474197,
      lng: -73.01596008452346,
    },
    site: "https://www.dirtytacoandtequila.com/",
  },
  {
    name: "Dirty Taco & Tequila Port Woodbury",
    seasonal: true,
    subText: "",
    address: {
      street: "8285 Jericho Tpke",
      city: "Woodbury",
      state: "NY",
      zipcode: "11797",
    },
    tel: "516-864-0068",
    latLng: {
      lat: 40.817021052073294,
      lng: -73.46628507113806,
    },
    site: "https://www.dirtytacoandtequila.com/",
  },
  {
    name: "Dirty Taco & Tequila Port Wantagh",
    seasonal: true,
    subText: "",
    address: {
      street: "3261 Merrick Rd",
      city: "Wantagh",
      state: "NY",
      zipcode: "11793",
    },
    tel: "516-785-5300",
    latLng: {
      lat: 40.66489467462759,
      lng: -73.50982104230988,
    },
    site: "https://www.dirtytacoandtequila.com/",
  },
  {
    name: "Jamesport Farm Brewery",
    seasonal: true,
    address: {
      street: "5873 Sound Ave",
      city: "Riverhead",
      state: "NY",
      zipcode: "11901",
    },
    tel: "844-532-2337",
    latLng: {
      lat: 40.98021767672084,
      lng: -72.59883202155103,
    },
    site: "https://www.jfbrewery.com/",
  },
];

export default RestaurantList;
