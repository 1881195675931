import * as React from "react";
import styled from "@emotion/styled";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { baseTheme } from "../../../styles/themes/theme";
import RestaurantList from "../../../data/Resturants";
import { Button, Row, Col, Container, Image } from "react-bootstrap";
import BlueBadge from "../../../images/Badge_Logo_Blue.png";
import BlackBadge from "../../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Black.png";
import WhiteBadge from "../../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_White.png";
import TanBadge from "../../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Tan.png";
import LocationSelectionContext from "../../../data/Contexts/FindUsContext";
import Stack from "react-bootstrap/Stack";
const LocationTitle = styled("h4")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1.2rem",
  fontWeight: "bold",
}));
const LocationAddress = styled("p")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.1rem",
  paddingTop: "0",
  marginBottom: "0",
}));
const LocationContact = styled("p")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "0.9rem",
  paddingTop: "0",
  marginBottom: "0",
}));
const LocationLink = styled(Button)(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1rem",
}));

const LocationDistanceText = styled("span")(() => ({
  textAlign: "center",
  margin: "auto",
  padding: 0,
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1rem",
}));

const ListGroupDisplayOLD = ({
  objArr,
  type,
  selectedItemCallback,
  selected,
}) => {
  return (
    <>
      {type === "restaurant" &&
        objArr.map((loc, index) => {
          return (
            <MyListGroupItem
              key={"rest-" + index}
              itemKey={"rest-" + index}
              index={index}
              loc={loc}
              badge={BlackBadge}
              //selected={selectedItem}
              //selectedItemCallback={(val) => setSelectedItem(val)}
            />
          );
        })}
      {type === "event" &&
        objArr.map((loc, index) => {
          return (
            <MyListGroupItem
              key={"events-" + index}
              index={index}
              loc={loc}
              itemKey={"events-" + index}
              badge={TanBadge}
              // selected={selectedItem}
              // selectedItemCallback={(val) => setSelectedItem(val)}
            />
          );
        })}
      {type === "iceCreamShop" &&
        objArr.map((loc, index) => {
          return (
            <MyListGroupItem
              key={"iceCreamShops-" + index}
              index={index}
              loc={loc}
              itemKey={"iceCreamShops-" + index}
              badge={WhiteBadge}
              // selected={selectedItem}
              // selectedItemCallback={(val) => setSelectedItem(val)}
            />
          );
        })}
      {type === "retailer" &&
        objArr.map((loc, index) => {
          if (loc.multipleLocations) {
            return loc.locations.map((location, index2) => {
              return (
                <MyListGroupItem
                  key={"retail-" + index + "-" + index2}
                  itemKey={"retail-" + index + "-" + index2}
                  index={index}
                  loc={{ ...location, retailerName: loc.name }}
                  badge={BlueBadge}
                  // selected={selectedItem}
                  // selectedItemCallback={(val) => setSelectedItem(val)}
                />
              );
            });
          } else
            return (
              <MyListGroupItem
                key={"retail-" + index}
                itemKey={"retail-" + index}
                index={index}
                loc={loc}
                badge={BlueBadge}
                // selected={selectedItem}
                // selectedItemCallback={(val) => setSelectedItem(val)}
              />
            );
        })}
    </>
  );
};

const MyListGroupItem = ({
  loc,
  itemKey,
  //selectedItemCallback,
  selected,
  badge,
}) => {
  const [locationDistance, setLocationDistance] = React.useState("");
  const pageContext = React.useContext(LocationSelectionContext);
  const selectedItem = pageContext.selectedMarker;
  const distances = pageContext.distances;
  const markers = pageContext.markerArray;

  // React.useEffect(() => {
  //   // console.log("page context");
  //   // console.log(pageContext.markerArray);
  //   // console.log(itemKey);
  //   // console.log("++++++++++++++++++++++++++++++++++++++++");
  //   if (pageContext && pageContext.markerArray) {
  //     // console.log("HERE");

  //     let marker = findMarker(itemKey, pageContext.markerArray);
  //     //console.log(marker);
  //     if (marker?.props?.details?.distances?.distance) {
  //       // console.log("MARKER");
  //       // console.log(marker);
  //       setLocationDistance(
  //         marker.props.details.distances.distance.duration.text
  //       );
  //     }
  //   }
  // }, [pageContext, markers]);

  React.useEffect(() => {
    console.log("Markers changed");
    if (pageContext && pageContext.markerArray) {
      let marker = findMarker(itemKey, pageContext.markerArray);
      //console.log(marker);
      if (marker?.props?.details?.distances?.distance) {
        setLocationDistance(
          marker.props.details.distances.distance.duration.text
        );
      }
    }
  }, [markers]);

  const locClicked = (loc) => {
    // console.log("Loc Clicked Function");
    // console.log(loc);
    pageContext.setSelectedMarker(findMarker(loc));
  };

  const markerDistanceKey = (key) => {
    console.log(distances);
    console.log(itemKey);
  };

  function findMarker(targetKey, newMarkers = null) {
    // console.log(markers);

    if (!markers || !targetKey) {
      // console.log("Markers or selectMarkerKeyNull");
      return null;
    }
    let markersSearchArray = markers;
    if (newMarkers) {
      markersSearchArray = newMarkers;
    }

    let found = markersSearchArray.filter(
      (x) => x.key.toString() === targetKey.toString()
    )[0];
    // console.log("MarkerFound");
    // console.log(found);
    return found;
  }

  let retailerName = loc.retailerName;
  let location = loc.Address ? loc.Address.location : "";
  let street = loc.Address ? loc.Address.street : loc.address.street;
  let city = loc.Address ? loc.Address.city : loc.address.city;
  let state = loc.Address ? loc.Address.state : loc.address.state;
  let zipcode = loc.Address ? loc.Address.zipcode : loc.address.zipcode;
  let seasonal = loc.seasonal ? "*" : "";
  return (
    <ListGroupItem key={itemKey} action onClick={() => locClicked(itemKey)}>
      <Container fluid>
        <Row>
          <Col
            xs={2}
            style={{ margin: "auto", borderRight: "1px solid black" }}
          >
            <Stack>
              <Image
                height={25}
                width={25}
                src={badge}
                style={{ margin: "auto" }}
                rounded
              />

              <LocationDistanceText>{locationDistance}</LocationDistanceText>
            </Stack>
          </Col>
          <Col xs={10}>
            {retailerName && <LocationTitle>{retailerName}</LocationTitle>}
            <LocationTitle>{loc.name ?? loc.Name}</LocationTitle>
            {loc.DateStr && <LocationTitle>{loc.DateStr}</LocationTitle>}
            {location && <LocationAddress>{location}</LocationAddress>}
            <LocationAddress>
              {street}, {city}, {state}, {zipcode}
            </LocationAddress>
          </Col>
        </Row>
      </Container>
    </ListGroupItem>
  );
};

export default ListGroupDisplayOLD;
