const RetailersList = [
  {
    name: "Broadway Gourmet & Caterers",
    address: {
      street: "893 N Broadway",
      city: "N Massapequa",
      zipcode: "11758",
      state: "NY",
    },
    tel: "516-797-4840",
    latLng: {
      lat: 40.6990011,
      lng: -73.4633942,
    },
    site: "https://www.broadwaygourmetcaterers.com",
  },
  {
    name: "Iavarone Brothers",
    multipleLocations: true,
    locations: [
      {
        name: "Cherrywood Shopping Center",
        address: {
          street: "1166 Wantagh Ave",
          city: "Wantagh",
          zipcode: "11793",
          state: "NY",
        },
        tel: "516-781-400",
        latLng: {
          lat: 40.6946243,
          lng: -73.5087784,
        },
      },
      {
        name: "Woodbury Village Shopping Center",
        address: {
          street: "7929 Jericho Tpke",
          city: "Woodbury",
          zipcode: "11797",
          state: "NY",
        },
        tel: "516-921-5400",
        latLng: {
          lat: 40.8166045,
          lng: -73.4768414,
        },
      },
    ],

    site: "https://www.ibfoods.com/wantagh/",
  },
  {
    name: "Sal's Meat Market",
    address: {
      street: "5127 Merrick Rd",
      city: "Massapequa Park",
      zipcode: "11762",
      state: "NY",
    },
    tel: "516-798-9755",
    latLng: {
      lat: 40.6670379,
      lng: -73.4469768,
    },
    site: "https://www.salsmeatmarket.com/",
  },
  {
    name: "Salpino Italian Food Market & Catering",
    address: {
      street: "1540 Newbridge Rd",
      city: "North Bellmore",
      zipcode: "11710",
      state: "NY",
    },
    tel: "516-900-1540",
    latLng: {
      lat: 40.6834399,
      lng: -73.5405233,
    },
    site: "https://www.salpino.com/",
  },
  // {
  //   name: "North Shore Farms",
  //   multipleLocations: true,
  //   locations: [
  //     // {
  //     //   name: "Glen Cove",
  //     //   tel: "516-609-0303",
  //     //   address: {
  //     //     street: "190 Glen Cove Ave",
  //     //     city: "Glencove",
  //     //     zipcode: "11542",
  //     //     state: "NY",
  //     //   },
  //     //   latLng: {
  //     //     lat: 40.8498254,
  //     //     lng: -73.6364655,
  //     //   },
  //     // },
  //     // {
  //     //   name: "Pt Washington",
  //     //   tel: "516-767-9050",
  //     //   address: {
  //     //     street: "770 Pt Washington Blvd",
  //     //     city: "Pt Washington",
  //     //     zipcode: "11050",
  //     //     state: "NY",
  //     //   },
  //     //   latLng: {
  //     //     lat: 40.8273194,
  //     //     lng: -73.684482,
  //     //   },
  //     // },
  //     // {
  //     //   name: "Great Neck",
  //     //   tel: "516-482-6287",
  //     //   address: {
  //     //     street: "90 Horace Harding Blvd",
  //     //     city: "Pt Washington",
  //     //     zipcode: "11020",
  //     //     state: "NY",
  //     //   },
  //     //   latLng: {
  //     //     lat: 40.7688246,
  //     //     lng: -73.7267748,
  //     //   },
  //     // },
  //     // {
  //     //   name: "Mineola",
  //     //   tel: "516-280-6880",
  //     //   address: {
  //     //     street: "330 East Jericho Tpke",
  //     //     city: "Mineola",
  //     //     zipcode: "11501",
  //     //     state: "NY",
  //     //   },
  //     //   latLng: {
  //     //     lat: 40.746777,
  //     //     lng: -73.6516859,
  //     //   },
  //     // },
  //     {
  //       name: "N. Bellmore",
  //       tel: "516-409-4800",
  //       address: {
  //         street: "2820  Jerusalem Ave",
  //         city: "N. Bellmore",
  //         zipcode: "11710",
  //         state: "NY",
  //       },
  //       latLng: {
  //         lat: 40.6912773,
  //         lng: -73.5302381,
  //       },
  //     },

  //     // {
  //     //   name: "Whitestone",
  //     //   tel: "718-767-3509",
  //     //   address: {
  //     //     street: "153-01 10th Ave",
  //     //     city: "Whitestone",
  //     //     zipcode: "11357",
  //     //     state: "NY",
  //     //   },
  //     //   latLng: {
  //     //     lat: 40.7942252,
  //     //     lng: -73.808197,
  //     //   },
  //     // },
  //     // {
  //     //   name: "Mamaroneck",
  //     //   tel: "914-381-2576",
  //     //   address: {
  //     //     street: "805 Mamaroneck Ave",
  //     //     city: "Mamaroneck",
  //     //     zipcode: "10543",
  //     //     state: "NY",
  //     //   },
  //     //   latLng: {
  //     //     lat: 40.9567146,
  //     //     lng: -73.7368977,
  //     //   },
  //     // },
  //     {
  //       name: "Hauppauge",
  //       tel: "631-366-2001",
  //       address: {
  //         street: "1235 Veterans Memorial Hwy",
  //         city: "Hauppauge",
  //         zipcode: "11788",
  //         state: "NY",
  //       },
  //       latLng: {
  //         lat: 40.8162414,
  //         lng: -73.1932999,
  //       },
  //     },
  //   ],
  //   site: "http://northshorefarms.com",
  // },
  {
    name: "Salpino's of Wantagh",
    address: {
      street: "3457 Merrick Road",
      city: "Wantagh",
      zipcode: "11793",
      state: "NY",
    },
    tel: "516-221-1100",
    latLng: {
      lat: 40.6653688,
      lng: -73.5054562,
    },
    site: "https://www.theoriginalsalpino.com/",
  },
  {
    name: "Greenport Fire",
    seasonal: true,
    address: {
      street: "125 Main St",
      city: "Greenport",
      zipcode: "11944",
      state: "NY",
    },
    tel: "631-333-2233",
    latLng: {
      lat: 41.1022489,
      lng: -72.3591726,
    },
    site: "https://www.feelthefireny.com/",
  },
  {
    name: "Larchmont Meateria | The Marketplace",
    address: {
      street: "9 Addison St",
      city: "Larchmont",
      zipcode: "10538",
      state: "NY",
    },
    tel: "914-833-0508",
    latLng: {
      lat: 40.9292607,
      lng: -73.7524433,
    },
    site: "https://www.larchmontmeateria.com/",
  },
  {
    name: "The Food Emporium",
    address: {
      street: "29-10,2910 Broadway",
      city: "Queens",
      zipcode: "11106",
      state: "NY",
    },
    tel: "(718) 274-4100",
    latLng: {
      lat: 40.7624159,
      lng: -73.9267705,
    },
    site: "http://foodemporium.keyfood.com/",
  },
  {
    name: "Babylon Village Meat Market",
    address: {
      street: "85 Deer Park Ave",
      city: "Babylon Village",
      zipcode: "11702",
      state: "NY",
    },
    tel: "(631) 669-0612",
    latLng: {
      lat: 40.6978835,
      lng: -73.3225302,
    },
    site: "https://www.babylonvillagemeatmarket.com/",
  },
  {
    name: "Gemelli Gourmet Market North",
    address: {
      street: "716 Glen Cove Ave",
      city: "Glen Head",
      zipcode: "11545",
      state: "NY",
    },
    tel: "(516) 200-9746",
    latLng: {
      lat: 40.8308176,
      lng: -73.6300916,
    },
    site: "https://www.gemelligourmetmarketnorth.com/",
  },
  {
    name: "The Barn",
    multipleLocations: true,
    locations: [
      {
        name: "Merrick",
        address: {
          street: "2081 Merrick Rd",
          city: "Merrick",
          zipcode: "11566",
          state: "NY",
        },
        tel: "(516) 517-2154",
        latLng: {
          lat: 40.6577743,
          lng: -73.5475814,
        },
      },
      {
        address: {
          street: "10 Belmont Ave",
          city: "West Babylon",
          zipcode: "11704",
          state: "NY",
        },
        tel: "(631) 526-9900",
        latLng: {
          lat: 40.7098564,
          lng: -73.3507401,
        },
      },
    ],
  },
  // {
  //   name: "Giunta's Meat Farms",
  //   multipleLocations: true,
  //   locations: [
  //     {
  //       address: {
  //         street: "1035 NY-109",
  //         city: "Farmingdale",
  //         zipcode: "11735",
  //         state: "NY",
  //       },
  //       tel: "(631) 393-2742",
  //       latLng: {
  //         lat: 40.724864342946674,
  //         lng: -73.4296782877949,
  //       },
  //     },
  //     {
  //       address: {
  //         street: "399 Medford Ave",
  //         city: "Patchogue",
  //         zipcode: "11772",
  //         state: "NY",
  //       },
  //       tel: "(631) 654-9628",
  //       latLng: {
  //         lat: 40.78232988375788,
  //         lng: -73.00671064213837,
  //       },
  //     },
  //     {
  //       address: {
  //         street: "395 Fort Salonga Rd",
  //         city: "Northport",
  //         zipcode: "11768",
  //         state: "NY",
  //       },
  //       tel: "(631) 651-8944",
  //       latLng: {
  //         lat: 40.89331953828575,
  //         lng: -73.34061818808964,
  //       },
  //     },
  //   ],
  //   site: "http://giuntasmeatfarms.com/",
  // },
  // {
  //   name: "Associated Supermarket",
  //   address: {
  //     street: "160 N Long Beach Rd",
  //     city: "Rockville Centre",
  //     zipcode: "11570",
  //     state: "NY",
  //   },
  //   tel: "(516) 766-7676",
  //   latLng: {
  //     lat: 40.65850152225149,
  //     lng: -73.62751815584713,
  //   },
  //   site: "https://www.shopassociated.com/",
  // },
  // {
  //   name: "A&S Fine Foods",
  //   address: {
  //     street: "3382 Long Beach Rd",
  //     city: "Oceanside",
  //     zipcode: "11572",
  //     state: "NY",
  //   },
  //   tel: "(516) 764-4606",
  //   latLng: {
  //     lat: 40.62855086913969,
  //     lng: -73.64151518315914,
  //   },
  //   site: "https://asoceanside.com/",
  // },
  // {
  //   name: "Farmer Joel's Market",
  //   address: {
  //     street: "177 Davison Ave",
  //     city: "Oceanside",
  //     zipcode: "11572",
  //     state: "NY",
  //   },
  //   tel: "(516) 766-5211",
  //   latLng: {
  //     lat: 40.64254163679031,
  //     lng: -73.63365816428107,
  //   },
  // },
  {
    name: "Pat's Marketplace",
    address: {
      street: "300 E Main St",
      city: "East Islip",
      state: "NY",
      zipcode: "11730",
    },
    tel: "(631) 446-1616",
    latLng: {
      lat: 40.73354483865737,
      lng: -73.17624060711779,
    },
    site: "https://www.patsmarketplace.com/",
  },
  {
    name: "Classic Marketplace",
    address: {
      street: "275 Long Beach Rd",
      city: "Island Park ",
      state: "NY",
      zipcode: "11558",
    },
    tel: "(516) 432-3708",
    latLng: {
      lat: 40.60466657894194,
      lng: -73.65526318436008,
    },
  },
  // {
  //   name: "EMF Gourmet Market",
  //   address: {
  //     street: "1958 Jericho Turnpike",
  //     city: "East Northport",
  //     state: "NY",
  //     zipcode: "11731",
  //   },
  //   tel: "(631) 493-0002",
  //   latLng: {
  //     lat: 40.83800311662429,
  //     lng: -73.33067162214651,
  //   },
  //   site: "http://www.emfgourmet.com/",
  // },
  {
    name: "Cherry Valley Marketplace",
    multipleLocations: true,
    locations: [
      {
        address: {
          street: "496 Hempstead Turnpike",
          city: "West Hempstead",
          state: "NY",
          zipcode: "11552",
        },
        tel: "(561) 292-2090",
        latLng: {
          lat: 40.70661001595263,
          lng: -73.65755925133709,
        },
      },
      {
        address: {
          street: "1115 Pennsylvania Ave",
          city: "Brooklyn",
          state: "NY",
          zipcode: "11207",
        },
        tel: "(718) 345-2873",
        latLng: {
          lat: 40.65397009777062,
          lng: -73.88668526488833,
        },
      },
    ],

    site: "http://cherryvalleymarketplace.com/496/",
  },
  {
    name: "Christina's Epicure",
    address: {
      street: "1015 Oyster Bay Rd",
      city: "East Norwich",
      state: "NY",
      zipcode: "11732",
    },
    tel: "516-922-5500",
    latLng: {
      lat: 40.84451030176523,
      lng: -73.53304710001252,
    },
    site: "https://christinasepicure.com/",
  },
  {
    name: "City Fresh Market",
    address: {
      street: "1380 Pennsylvania Ave",
      city: "Brooklyn",
      state: "NY",
      zipcode: "11239",
    },
    tel: "718-542-1608",
    latLng: {
      lat: 40.646882722148305,
      lng: -73.88384700988593,
    },
    site: "http://www.mycityfreshmarket.com/1380",
  },
  {
    name: "Salpino of Babylon",
    address: {
      street: "38 Deer Park Ave",
      city: "N Babylon",
      zipcode: "11239",
      state: "NY",
    },
    tel: "631-242-5500",
    latLng: {
      lat: 40.74899705857806,
      lng: -73.32326555553347,
    },
    site: "https://www.salpino.com/",
  },
  {
    name: "Milk N' Things",
    address: {
      street: "1287A Deer Park Ave",
      city: "N Babylon",
      zipcode: "11703",
      state: "NY",
    },
    latLng: {
      lat: 40.74053709809386,
      lng: -73.32083664186733,
    },
  },
  /////////////////////////////////
  {
    name: "Justin's Chop Shop",
    address: {
      street: "71 Sunset Ave",
      city: "Westhampton Beach,",
      zipcode: "11978",
      state: "NY",
    },
    tel: "(631) 288-5532",
    latLng: {
      lat: 40.81164616669176,
      lng: -72.64525945045345,
    },
  },
  {
    name: "Uncle Giuseppe's",
    multipleLocations: true,
    locations: [
      {
        name: "Port Jefferson Station",
        address: {
          street: "1108 Route 112",
          city: "Port Jefferson Station",
          zipcode: "11776",
          state: "NY",
        },
        latLng: {
          lat: 40.918348395917775,
          lng: -73.04089775174562,
        },
      },
      {
        name: "Smithtown",
        address: {
          street: "95 Route 111",
          city: "Smithtownn",
          zipcode: "11787",
          state: "NY",
        },
        latLng: {
          lat: 40.85287487401256,
          lng: -73.18596963712483,
        },
      },
      {
        name: "Yorktown Heights",
        address: {
          street: "327 Downing Drive",
          city: "Yorktown Heights",
          zipcode: "10598",
          state: "NY",
        },
        latLng: {
          lat: 41.271760069556834,
          lng: -73.78170994227007,
        },
      },
      {
        name: "Melville",
        address: {
          street: "890 Walt Whitman Road",
          city: "Melville",
          zipcode: "11747",
          state: "NY",
        },
        latLng: {
          lat: 40.79831816750036,
          lng: -73.41074639999796,
        },
      },
      {
        name: "North Babylon",
        address: {
          street: "1170 Deer Park Avenue",
          city: "North Babylon",
          zipcode: "11703",
          state: "NY",
        },
        latLng: {
          lat: 40.73631870051419,
          lng: -73.32162099998976,
        },
      },
    ],
  },
  {
    name: "Southdown Marketplace",
    multipleLocations: true,
    locations: [
      {
        name: "W. Islip",
        address: {
          street: "460 Montauk Hwy",
          city: "W. Islip",
          zipcode: "11743",
          state: "NY",
        },
        latLng: {
          lat: 40.696893883021104,
          lng: -73.31105142885544,
        },
      },
      {
        name: "Huntington",
        address: {
          street: "205 Wall Street",
          city: "Huntington",
          zipcode: "11743",
          state: "NY",
        },
        latLng: {
          lat: 40.878595001867346,
          lng: -73.42595495982654,
        },
      },
    ],
  },
  {
    name: "Greenlawn Farms",
    address: {
      street: "777 Pulaski Rd",
      city: "Greenlawn",
      state: "NY",
      zipcode: "11740",
    },
    latLng: {
      lat: 40.86761878177513,
      lng: -73.35001194226804,
    },
  },
  {
    name: "Cow Palace Butcher Shop",
    address: {
      street: "457 NY Route 25A",
      city: "Rocky Point",
      state: "NY",
      zipcode: "11778",
    },
    tel: "631-744-0114",
    latLng: {
      lat: 40.94639332840955,
      lng: -72.93581707818397,
    },
  },
  {
    name: "Pino's Italian Market",
    address: {
      street: "683 Old Country Rd",
      city: "Dix Hills",
      state: "NY",
      zipcode: "11746",
    },
    tel: "631-916-4437",
    latLng: {
      lat: 40.83121181729259,
      lng: -73.36013450694328,
    },
  },
  {
    name: "Grace's Marketplace",
    address: {
      street: "81 Glen Cove Rd",
      city: "Greenvale",
      state: "NY",
      zipcode: "11548",
    },
    tel: "516-621-5100",
    latLng: {
      lat: 40.81414790076802,
      lng: -73.62729532203612,
    },
  },
  {
    name: "1760 Homestead Farm",
    address: {
      street: "5412 Sound Ave",
      city: "Riverhead",
      state: "NY",
      zipcode: "11901",
    },
    tel: "631-831-1653",
    latLng: {
      lat: 40.97325241230908,
      lng: -72.61423422179877,
    },
  },
];

function retailersCount() {
  let count = 0;
  RetailersList.forEach((rest) => {
    if (rest.multipleLocations) {
      count += rest.locations.length;
    } else {
      count++;
    }
  });
  return count;
}

export const retailCount = retailersCount();

export default RetailersList;
