export const IceCreamShops = [
  {
    name: "Sweet Spot Cafe",
    address: {
      street: "3787 Merrick Rd",
      city: "Seaford",
      state: "NY",
      zipcode: "11783",
    },
    tel: "(631) 308-3843",
    latLng: {
      lat: 40.665883737852916,
      lng: -73.49123042681082,
    },
  },
  // {
  //   name: "So Sveglio Espresso Bar",
  //   address: {
  //     street: "124 S Wellwood Ave",
  //     city: "Lindenhurst",
  //     state: "NY",
  //     zipcode: "11757",
  //   },
  //   tel: "(631) 412-3750",
  //   latLng: {
  //     lat: 40.68674337972028,
  //     lng: -73.37302905334566,
  //   },
  //   site: "https://sosveglio.com/",
  // },
];

export default IceCreamShops;
